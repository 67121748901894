export interface ApiError {
    error: string
}

export interface DataPage {
    next?: string | null | undefined
}

export const twentyFourHoursInMs = 1000 * 60 * 60 * 24

export const accountsApi = "https://accounts.pavuk.ai"
export const semanticApi = "https://ae-api.semanticforce.ai/data"
// export const pavukApi = "https://api.pavuk.ai"
export const pavukApi = "https://dev-api.pavuk.ai"
export const devPavukApi = "https://dev-api.pavuk.ai"
export const exportsArchivePath = "https://application-storage.informio.biz/sf-ai"